import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';
import {
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
  FunctionField,
  CreateButton
} from 'react-admin';
import { UpLoadFile, PreviewImage } from './custom/UpLoadFile';
import { ArrowBack } from '@material-ui/icons';
//import ResetOrderNum from './Salesman_ResetOrderNum';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';


{/* 
  import { SalesmanList, SalesmanCreate, SalesmanEdit, SalesmanShow } from './components/Salesman';

  <Resource name="CMSSalesman" list={SalesmanList} create={SalesmanCreate} edit={SalesmanEdit} show={SalesmanShow} />
  <Resource name="CMSSalesman" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
});

const useTxtData = {
  page: {
    tableName: '业务员',
  },
  table: {
    id: 'id',
    Name: '姓名',
    Sex: '性别',
    Mobile: '手机',
    Email: '邮箱',
    Avatar: '头像',
    CreateTime: '创建时间',
    OrderNum: '排序',
    Remark: '备注',
  }
};

//分页列表页面
export const SalesmanList = (props) => {
  const { basePath } = props;
  const redirect = useRedirect();

  //筛选器模块
  const Filters = (props) => {
    return (
      <Filter {...props}>
        {/* <TextInput label="ID" source="id" alwaysOn resettable /> */}
        {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
      </Filter>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" />
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton {...props} /><br />
        <ShowButton {...props} /><br />
        <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" />
      </div>
    );
  }
  //表格右上角功能按钮
  const ListActions = ({ basePath }) => {
    return (
      <TopToolbar>
        <CreateButton basePath={basePath} />
      </TopToolbar>
    );
  }
  //返回到列表
  const handleClose = () => {
    redirect(basePath);
  };

  const classes = useStyles();
  return (
    <>
      <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'DESC' }} filters={<Filters />} bulkActionButtons={<AssetBulkActionButtons />} actions={<ListActions />} exporter={false}>
        <Datagrid>
          {/* <TextField source="id" /> */}
          {/* <TextField label="名称" source="Name" /> */}
          {/* <TextField label="排序" source="OrderNum" />  */}
          <TextField label={useTxtData.table.id} source="id" />
          <PreviewImage label={useTxtData.table.Avatar} source="Avatar" className={classes.imageField} />
          <TextField label={useTxtData.table.Name} source="Name" />
          <FunctionField label={useTxtData.table.Sex} render={record => <div>{record['Sex'] === 2 ? '女' : '男'}</div>} />
          <TextField label={useTxtData.table.Mobile} source="Mobile" />
          <TextField label={useTxtData.table.Email} source="Email" />
          <TextField label={useTxtData.table.OrderNum} source="OrderNum" />
          <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
          {/* <TextField label={useTxtData.table.Remark} source="Remark" /> */}
          <RowAction />
        </Datagrid>
      </List>
      <Route path={`${basePath}/create`} >
        {({ match }) => (
          <Drawer
            open={!!match}
            anchor="right"
            onClose={handleClose}
          >
            <SalesmanCreate {...props}
              onCancel={handleClose}
            />
          </Drawer>
        )}
      </Route>
      <Route path={`${basePath}/:id`}>
        {({ match }) => {
          const isMatch =
            match &&
            match.params &&
            match.params.id !== 'create';
          return (<Drawer
            open={isMatch}
            anchor="right"
            onClose={handleClose}
          >
            {isMatch ? (
              <SalesmanEdit {...props}
                id={isMatch ? match.params.id : null}
                onCancel={handleClose}
              />
            ) : null}
          </Drawer>)
        }}
      </Route>
    </>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const SalesmanShow = (props) => {
  const ShowActions = (props) => {
    const { basePath, data } = props; const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    );
  };

  const classes = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} {...props} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.Name} source="Name" />
        <TextField label={useTxtData.table.Sex} source="Sex" />
        <TextField label={useTxtData.table.Mobile} source="Mobile" />
        <TextField label={useTxtData.table.Email} source="Email" />
        <TextField label={useTxtData.table.Avatar} source="Avatar" />
        <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
        <TextField label={useTxtData.table.OrderNum} source="OrderNum" />
        <TextField label={useTxtData.table.Remark} source="Remark" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const SalesmanEdit = (props) => {
  const PostEditActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const SalesmanCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <TextInput label={useTxtData.table.id} source="id" validate={[required()]} /> */}
      <UpLoadFile label={useTxtData.table.Avatar} source="Avatar" validate={[]} />
      <TextInput label={useTxtData.table.Name} source="Name" validate={[required()]} />
      <SelectInput label={useTxtData.table.Sex} source="Sex" choices={[
        { id: 1, name: '男' },
        { id: 2, name: '女' }
      ]} validate={[required()]} />
      <TextInput label={useTxtData.table.Mobile} source="Mobile" validate={[required()]} />
      <TextInput label={useTxtData.table.Email} source="Email" validate={[]} />
      {/* <TextInput label={useTxtData.table.CreateTime} source="CreateTime" validate={[required()]} /> */}
      <TextInput label={useTxtData.table.OrderNum} source="OrderNum" validate={[required()]} defaultValue={0} />
      <TextInput fullWidth label={useTxtData.table.Remark} source="Remark" validate={[]} />
    </SimpleForm>
  );
}