import * as React from 'react';
import { Fragment } from 'react';
import { useState, useEffect } from 'react';
import { Button as CButton, TextField as CTextField, Box, Typography, Drawer, Card, CardContent, Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, FormDataConsumer, useDataProvider, Loading, Error, useNotify, useRefresh, ArrayInput, SimpleFormIterator } from 'react-admin';
import {
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,

  TabbedShowLayout,
  Tab,
  TabbedForm,
  FormTab,
  FunctionField,
  DateInput,
  useListContext
} from 'react-admin';
import { UpLoadFile } from './custom/UpLoadFile';
import { ArrowBack } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import Search from '@material-ui/icons/Search';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
//import ResetOrderNum from './SKOrder_ResetOrderNum';
import { apiUrl } from '../global';
import { httpClient, httpClientReturnFile } from '../dataProvider';
import Backdrop from '@material-ui/core/Backdrop';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import qs from 'qs';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SelectByEnum } from './custom/Enum';
import { Form } from 'react-final-form';
import { useForm } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { VIP } from './custom/Public';

{/* 
  import { SKOrderList, SKOrderCreate, SKOrderEdit, SKOrderShow } from './components/SKOrder';

  <Resource name="CMSSKOrder" list={SKOrderList} create={SKOrderCreate} edit={SKOrderEdit} show={SKOrderShow} />
  <Resource name="CMSSKOrder" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles((theme) => {
  console.log('theme', theme)
  return createStyles({
    imageField: {
      '& img': { width: 60 }
    },
    errorIcon: {
      fontSize: 15,
      verticalAlign: 'text-bottom',
      color: theme.palette.text.hint
    },
    errorIcon2: {
      fontSize: 15,
      verticalAlign: 'text-bottom',
      color: 'red'
    },
    table: {
      width: 'auto'
    },
    remark: {
      maxWidth: 130,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    vip: {
      color: 'green'
    },
    errBtn: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      }
    },
    backdrop: {
      zIndex: theme.zIndex.tooltip + 1,
      color: '#fff',
    },
    dialog: {
      "& .MuiPaper-root": {
        minWidth: 500
      }
    }
  })
});

const useTxtData = {
  page: {
    tableName: 'VIP',
  },
  table: {

  }
};

//分页列表页面
export const SKVipVisitorList = (props) => {

  const classes = useStyles();

  //筛选器模块
  const Filters = (props) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, } = useListContext();
    if (props.context === "button") return null;
    const onSubmit = values => { if (Object.keys(values).length > 0) { setFilters(values); } else { setFilters({}, []); } };
    const resetFilter = () => { setFilters({}, []); };
    return (
      <div>
        <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box display='flex' alignItems='center'>
                <Box component="span" mr={2}>
                  <TextInput label="订单号/行程" source="key" alwaysOn resettable />
                </Box>
                <Box component="span" mr={2}>
                  <DateInput label="行程时间" source="IourneyStartTime" alwaysOn resettable />
                </Box>
                <Box component="span" mr={1}>
                  <DateInput label="起始创建时间" source="StartCreateTime" resettable />
                </Box>
                <Box component="span" mr={1}>
                  -
                </Box>
                <Box component="span" mr={2}>
                  <DateInput label="截止创建时间" source="EndCreateTime" resettable />
                </Box>
                <Box component="span" mr={2} mb={2.5}>
                  <CButton variant="contained" color="primary" type="submit" startIcon={<Search />}>搜索</CButton>
                </Box>
                <Box component="span" mr={2} mb={2.5}>
                  <CButton variant="outlined" onClick={resetFilter}>重置</CButton>
                </Box>
              </Box>
            </form>
          )}
        </Form>
      </div>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        {/* <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    let { OrderStatusReview, OrderStatusFiling } = props.record;
    return (
      <div style={{ textAlign: 'right' }}>

      </div>
    );
  }

  const DealStatus = ({ status, remark }) => {
    // 创建中, 审核中, 已审核, 驳回审核, 已取消
    // 未归档, 归档中, 已归档, 驳回归档
    switch (status) {
      case '创建中': return <span style={{}}>{status}</span>
      case '审核中': return <span style={{ color: '#ff5316' }}>{status}</span>
      case '已审核': return <span style={{ color: 'green' }}>{status}</span>
      case '驳回审核': return <>
        <span style={{ color: 'red' }}>{status}</span>
        {remark &&
          <span title={remark}><ErrorIcon className={classes.errorIcon2} /></span>
        }
      </>
      case '已取消': return <span style={{ color: 'grey' }}>{status}</span>
      /////////
      case '未归档': return <span style={{}}>{status}</span>
      case '归档中': return <span style={{ color: '#ff5316' }}>{status}</span>
      case '已归档': return <span style={{ color: 'green' }}>{status}</span>
      case '驳回归档': return <>
        <span style={{ color: 'red' }}>{status}</span>
        {remark &&
          <span title={remark}><ErrorIcon className={classes.errorIcon2} /></span>
        }
      </>
    }
    return null;
  }

  const ShowActions = (props) => {
    const [loading, setLoading] = useState(true);
    const { displayedFilters, filterValues, setFilters, hideFilter, } = useListContext();
    const exportHandle = () => {
      if (window.confirm('一次最多导出1000条数据')) {
        setLoading(true);
        console.log('filterValues', filterValues)
        httpClientReturnFile('游客名单(VIP)', `${apiUrl}/Admin/CMSSKVipVisitor/GetList?${qs.stringify({ ...filterValues, export: true })}`, {
          method: 'GET',
          // contentType: 'application/x-www-form-urlencoded',
          // body: qs.stringify({  })
        }).then((result) => {
          if (result) {
            var anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = result;
            anchor.target = "_blank";
            anchor.download = `游客名单(VIP).xlsx`;
            anchor.click();
          }
        }).finally(() => {
          setLoading(true);
        })
      }
    }
    return (
      <TopToolbar>
        <CButton onClick={exportHandle}>导出</CButton>
      </TopToolbar >
    );
  };

  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'CreateTime', order: 'DESC' }} filters={<Filters />} bulkActionButtons={false} exporter={false} actions={<ShowActions />} >
      <Datagrid>
        <TextField label={'订单号'} source="OrderNumber" sortable={false} />

        <FunctionField label={'创建时间'} sortable={true} sortBy='CreateTime' render={record => <>
          <div>{record['CreateTime'].split(' ')[0]}</div>
        </>} />

        <FunctionField label={'行程时间'} sortable={true} sortBy='IourneyStartTime' render={record => <>
          <div>{record['IourneyStartTime'].split(' ')[0]}</div>
        </>} />

        <FunctionField label="行程信息" render={record => <>
          <div style={{
            maxWidth: 260,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical'
          }}>{record['IourneyTitle']}</div>
        </>} />

        <FunctionField label={'审核状态'} render={record => <>
          <div><DealStatus status={record['OrderStatusReview']} remark={record['RejectReviewRemark']} /></div>
        </>} />
        <FunctionField label={'归档状态'} render={record => <>
          <div><DealStatus status={record['OrderStatusFiling']} remark={record['RejectFilingRemark']} /></div>
        </>} />

        <TextField label={'游客姓名'} source="Name" sortable={false} />
        <FunctionField label={'游客性别'} render={(record) => <span>{record['Sex'] === 2 ? '女' : '男'}</span>} />
        <TextField label={'证件类型'} source="TypeOfCertificate" sortable={false} />
        <TextField label={'证件号'} source="CertificateNumber" sortable={false} />
        <TextField label={'手机号'} source="Mobile" sortable={false} />
        <TextField label={'VIP备注'} source="VipRemark" sortable={false} />
        <RowAction />
      </Datagrid>
    </List >
  );
};
