import * as React from 'react';
import { Fragment } from 'react';
import { useState, useEffect } from 'react';
import { Button as CButton, TextField as CTextField, Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, FormDataConsumer, useDataProvider, Loading, Error, useNotify, useRefresh, ArrayInput, SimpleFormIterator } from 'react-admin';
import {
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,
  AutocompleteInput,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,

  TabbedShowLayout,
  Tab,
  TabbedForm,
  FormTab,
  FunctionField,
  DateInput,
  useListContext
} from 'react-admin';
import { UpLoadFile } from '../custom/UpLoadFile';
import { ArrowBack } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import Search from '@material-ui/icons/Search';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
//import ResetOrderNum from './SKOrder_ResetOrderNum';
import { apiUrl } from '../../global';
import { httpClient, uploadFile } from '../../dataProvider';
import Backdrop from '@material-ui/core/Backdrop';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import qs from 'qs';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SelectByEnum } from '../custom/Enum';
import { Form } from 'react-final-form';
import { useForm } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { VIP } from '../custom/Public';

{/* 
  import { SKOrderList, SKOrderCreate, SKOrderEdit, SKOrderShow } from './components/SKOrder';

  <Resource name="CMSSKOrder" list={SKOrderList} create={SKOrderCreate} edit={SKOrderEdit} show={SKOrderShow} />
  <Resource name="CMSSKOrder" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles((theme) => {
  console.log('theme', theme)
  return createStyles({
    input: {
      display: 'none',
    },
    imageField: {
      '& img': { width: 60 }
    },
    errorIcon: {
      fontSize: 15,
      verticalAlign: 'text-bottom',
      color: theme.palette.text.hint
    },
    errorIcon2: {
      fontSize: 15,
      verticalAlign: 'text-bottom',
      color: 'red'
    },
    table: {
      width: 'auto'
    },
    remark: {
      maxWidth: 130,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    vip: {
      color: 'green'
    },
    errBtn: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      }
    },
    backdrop: {
      zIndex: theme.zIndex.tooltip + 1,
      color: '#fff',
    },
    dialog: {
      "& .MuiPaper-root": {
        minWidth: 500
      }
    }
  })
});

const useTxtData = {
  page: {
    tableName: 'SKOrder',
  },
  table: {
    id: 'id',
    OrderNumber: '订单号',
    OrderStatusReview: '审核状态',
    RejectReviewRemark: '',
    OrderStatusFiling: '归档状态',
    RejectFilingRemark: '',
    SalesmanId: '业务',
    TravelAgencySalesmanId: '业务',
    TravelAgency_AdminId: '经销商',
    Meter_AdminId: '计调',
    IourneyStartTime: '行程日期',
    IourneyTitle: '行程标题',
    CreateTime: '创建时间',
    UpdateTime: '更新时间',
    OrderRemark: '订单备注',
  }
};

//分页列表页面
export const SKOrderList = (props) => {

  const classes = useStyles();

  //筛选器模块
  const Filters = (props) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, } = useListContext();
    if (props.context === "button") return null;
    const onSubmit = values => { if (Object.keys(values).length > 0) { setFilters(values); } else { setFilters({}, []); } };
    const resetFilter = () => { setFilters({}, []); };
    return (
      <div>
        <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box display='flex' alignItems='center'>
                <Box component="span" mr={2}>
                  <TextInput label="订单号/行程" source="key" alwaysOn resettable />
                </Box>
                <Box component="span" mr={2}>
                  <ReferenceInput label={useTxtData.table.TravelAgencySalesmanId} source="TravelAgencySalesmanId" reference="TravelAgency/CMSTravelAgencySalesman" alwaysOn resettable >
                    <SelectInput optionText="Name" />
                  </ReferenceInput>
                </Box>
                <Box component="span" mr={2}>
                  <DateInput label="行程时间" source="IourneyStartTime" alwaysOn resettable />
                </Box>
                <Box component="span" mr={1}>
                  <DateInput label="起始创建时间" source="StartCreateTime" resettable />
                </Box>
                <Box component="span" mr={1}>
                  -
                </Box>
                <Box component="span" mr={2}>
                  <DateInput label="截止创建时间" source="EndCreateTime" resettable />
                </Box>
                <Box component="span" mr={2} mb={2.5}>
                  <CButton variant="contained" color="primary" type="submit" startIcon={<Search />}>搜索</CButton>
                </Box>
                <Box component="span" mr={2} mb={2.5}>
                  <CButton variant="outlined" onClick={resetFilter}>重置</CButton>
                </Box>
              </Box>
            </form>
          )}
        </Form>
      </div>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" />
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    let { OrderStatusReview, OrderStatusFiling } = props.record;
    return (
      <div style={{ textAlign: 'right' }}>
        {(OrderStatusReview === '创建中' || OrderStatusReview === '驳回审核') &&
          <><EditButton {...props} /><br /></>
        }
        <ShowButton {...props} /><br />
        {isRecyclePage &&
          <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" />
        }
      </div>
    );
  }

  const DealStatus = ({ status, remark }) => {
    // 创建中, 审核中, 已审核, 驳回审核, 已取消
    // 未归档, 归档中, 已归档, 驳回归档
    switch (status) {
      case '创建中': return <span style={{}}>{status}</span>
      case '审核中': return <span style={{ color: '#ff5316' }}>{status}</span>
      case '已审核': return <span style={{ color: 'green' }}>{status}</span>
      case '驳回审核': return <>
        <span style={{ color: 'red' }}>{status}</span>
        {remark &&
          <span title={remark}><ErrorIcon className={classes.errorIcon2} /></span>
        }
      </>
      case '已取消': return <span style={{ color: 'grey' }}>{status}</span>
      /////////
      case '未归档': return <span style={{}}>{status}</span>
      case '归档中': return <span style={{ color: '#ff5316' }}>{status}</span>
      case '已归档': return <span style={{ color: 'green' }}>{status}</span>
      case '驳回归档': return <>
        <span style={{ color: 'red' }}>{status}</span>
        {remark &&
          <span title={remark}><ErrorIcon className={classes.errorIcon2} /></span>
        }
      </>
    }
    return null;
  }
  let isRecyclePage = props.resource.indexOf('Recycle') > 0;
  let status = isRecyclePage ? { ShowCancel: true } : {};
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'CreateTime', order: 'DESC' }} filters={<Filters />} bulkActionButtons={isRecyclePage ? <AssetBulkActionButtons /> : false} exporter={false} filter={status} >
      <Datagrid expand={<Visitor />}>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        {/* <TextField label={useTxtData.table.id} source="id" /> */}

        <FunctionField label={'订单信息'} render={record => <>
          {/* <div>{record['OrderNumber']}</div>
          <div> */}
          {/* <span>{record['CreateTime'].split(' ')[0]}</span> */}
          <span>{record['OrderNumber']}</span>
          {
            record['OrderRemark'] && <span title={record['OrderRemark']}><ErrorIcon className={classes.errorIcon} /></span>
          }
          {/* </div> */}
        </>} />

        {/* <TextField label={useTxtData.table.OrderStatusReview} source="OrderStatusReview" /> */}
        {/* <TextField label={useTxtData.table.RejectReviewRemark} source="RejectReviewRemark" /> */}
        {/* <TextField label={useTxtData.table.OrderStatusFiling} source="OrderStatusFiling" /> */}
        {/* <TextField label={useTxtData.table.RejectFilingRemark} source="RejectFilingRemark" /> */}
        <FunctionField label={'订单状态'} render={record => <>
          <div><DealStatus status={record['OrderStatusReview']} remark={record['RejectReviewRemark']} /></div>
          {/* <div><DealStatus status={record['OrderStatusFiling']} remark={record['RejectFilingRemark']} /></div> */}
        </>} />

        {/* <TextField label={useTxtData.table.TravelAgency_AdminId} source="TravelAgency_AdminId" /> */}
        {/* <ReferenceField label="经销商" source="TravelAgency_AdminId" reference="TravelAgency/CMSAdmin_TravelAgency" link={false} sortable={false}>
          <>
            <TextField source="Account" />(<TextField source="NickName" />)
          </>
        </ReferenceField> */}

        {/* <TextField label={useTxtData.table.SalesmanId} source="SalesmanId" /> */}
        {/* <TextField label={useTxtData.table.Meter_AdminId} source="Meter_AdminId" /> */}

        <ReferenceField label="业务" source="TravelAgencySalesmanId" reference="TravelAgency/CMSTravelAgencySalesman" link={false} sortable={false}>
          <TextField source="Name" />
        </ReferenceField>

        {/* <TextField label={useTxtData.table.IourneyStartTime} source="IourneyStartTime" /> */}
        {/* <TextField label={useTxtData.table.IourneyTitle} source="IourneyTitle" /> */}
        <FunctionField label="行程信息" render={record => <>
          <div style={{
            maxWidth: 260,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical'
          }}>{record['IourneyTitle']}</div>
          {/* <div>{record['IourneyStartTime'].split(' ')[0]}</div> */}
        </>} />

        <FunctionField label="区域" render={record => <>
          <ReferenceField source="ProvinceId" reference="TravelAgency/CMSProvince" link={false}>
            <TextField source="Name" />
          </ReferenceField>
          <span> </span>
          <ReferenceField source="CityId" reference="TravelAgency/CMSCity" link={false} >
            <TextField source="Name" />
          </ReferenceField>
        </>} />

        <FunctionField label={'游客数量'} render={record => <>
          <span>{record['TotalVisitors']}</span>
        </>} />

        <FunctionField label={useTxtData.table.IourneyStartTime} sortable={true} sortBy='IourneyStartTime' render={record => <>
          <div>{record['IourneyStartTime'].split(' ')[0]}</div>
        </>} />
        <FunctionField label={useTxtData.table.CreateTime} sortable={true} sortBy='CreateTime' render={record => <>
          <div>{record['CreateTime'].split(' ')[0]}</div>
        </>} />
        <TextField label={useTxtData.table.UpdateTime} source="UpdateTime" />
        {/* <TextField label={useTxtData.table.OrderRemark} source="OrderRemark" /> */}
        {/* <FunctionField label={useTxtData.table.OrderRemark} render={record => <>
          <div style={{ maxWidth: 300 }}>{record['OrderRemark']}</div>
        </>} /> */}
        <RowAction />
      </Datagrid>
    </List >
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
const Visitor = ({ record }) => {
  const classes = useStyles();

  const notify = useNotify();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    httpClient(`${apiUrl}/TravelAgency/CMSSKOrder/GetVisitorList`, {
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      body: qs.stringify({ orderNumber: record['OrderNumber'] })
    }).then(({ json }) => {
      setLoading(false);
      if (json.Code == 1)
        setData(json.Result);
      else {
        notify(json.Message);
      }
    })
  }, []);

  if (loading) return <CircularProgress />;
  if (!data) return null;

  return (
    <Table className={classes.table} size="small">
      <TableHead>
        <TableRow>
          <TableCell>编号</TableCell>
          <TableCell>姓名</TableCell>
          <TableCell>性别</TableCell>
          <TableCell>证件</TableCell>
          <TableCell>手机</TableCell>
          <TableCell>备注(经销商)</TableCell>
          {/* <TableCell>备注(计调)</TableCell>
          <TableCell>备注(平台)</TableCell>
          <TableCell>VIP</TableCell>
          <TableCell>备注(VIP)</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(({ id, Name, Sex, TypeOfCertificate, CertificateNumber, Mobile, TravelAgency_Remark, Meter_Remark, Admin_Remark, SetVip, VipRemark }, index) => (
          <TableRow key={id}>
            <TableCell>
              {index + 1}
            </TableCell>
            <TableCell>{Name}</TableCell>
            <TableCell>{Sex === 1 ? "男" : "女"}</TableCell>
            <TableCell>[{TypeOfCertificate}]{CertificateNumber}</TableCell>
            <TableCell>{Mobile}</TableCell>
            <TableCell className={classes.remark} title={TravelAgency_Remark}>{TravelAgency_Remark}</TableCell>
            {/* <TableCell className={classes.remark} title={Meter_Remark}>{Meter_Remark}</TableCell>
            <TableCell className={classes.remark} title={Admin_Remark}>{Admin_Remark}</TableCell>
            <TableCell>{SetVip ? <CheckCircleIcon className={classes.vip} /> : null}</TableCell>
            <TableCell className={classes.remark} title={VipRemark}>{VipRemark}</TableCell> */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
};

const Log = ({ record }) => {
  const classes = useStyles();

  const notify = useNotify();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    httpClient(`${apiUrl}/TravelAgency/CMSSKOrder/GetOrderLogs`, {
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      body: qs.stringify({ orderNumber: record['OrderNumber'] })
    }).then(({ json }) => {
      setLoading(false);
      if (json.Code == 1)
        setData(json.Result);
      else {
        notify(json.Message);
      }
    })
  }, []);

  if (loading) return <CircularProgress />;
  if (!data) return null;

  const CAdminType = (AdminType) => {
    switch (AdminType) {
      case 'Admin': return '平台';
      case 'Meter': return '计调';
      case 'TravelAgency': return '经销商';
    }
  }

  return (
    <Table className={classes.table} size="small">
      <TableHead>
        <TableRow>
          <TableCell>时间</TableCell>
          <TableCell>角色</TableCell>
          <TableCell>操作人</TableCell>
          <TableCell>日志主题</TableCell>
          <TableCell>前内容</TableCell>
          <TableCell>后内容</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(({ id, AdminType, AdminAccount, AdminNickName, AdminAvatar, LogTitle, LogBeforeContent, LogAfterContent, CreateTime }) => (
          <TableRow key={id}>
            <TableCell>{CreateTime}</TableCell>
            <TableCell>{CAdminType(AdminType)}</TableCell>
            <TableCell><img style={{ width: 20, verticalAlign: 'text-bottom', borderRadius: 20 }} src={AdminAvatar} /> {AdminAccount}({AdminNickName})</TableCell>
            <TableCell>{LogTitle}</TableCell>
            <TableCell>{LogBeforeContent}</TableCell>
            <TableCell>{LogAfterContent}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

const SubmitReviewBtn = ({ record }) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
  }, []);

  const clickHandler = () => {
    if (window.confirm('确定要提交订单吗？')) {
      setLoading(true);
      httpClient(`${apiUrl}/TravelAgency/CMSSKOrder/SubmitReview`, {
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        body: qs.stringify({ orderNumber: record['OrderNumber'] })
      }).then(({ json }) => {
        setLoading(false);
        if (json.Code == 1)
          refresh();
        else {
          notify(json.Message);
        }
      })
    }
  }

  return <>
    <Box mr={1}>
      <CButton variant="contained" color="secondary" onClick={clickHandler}>
        提交审核
      </CButton>
    </Box>
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress />
    </Backdrop>
  </>
}

const CloseOrderBtn = ({ record }) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
  }, []);

  const clickHandler = () => {
    if (window.confirm('确定要取消并关闭订单吗？')) {
      setLoading(true);
      httpClient(`${apiUrl}/TravelAgency/CMSSKOrder/CloseOrder`, {
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        body: qs.stringify({ orderNumber: record['OrderNumber'] })
      }).then(({ json }) => {
        setLoading(false);
        if (json.Code == 1)
          refresh();
        else {
          notify(json.Message);
        }
      })
    }
  }

  return <>
    <Box mr={1}>
      <CButton variant="contained" className={classes.errBtn} onClick={clickHandler}>
        取消订单
      </CButton>
    </Box>
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress />
    </Backdrop>
  </>
}

const EecReviewBtn = ({ record }) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
  }, []);

  const clickHandler = () => {
    if (window.confirm('确定要取消审核订单吗？')) {
      setLoading(true);
      httpClient(`${apiUrl}/TravelAgency/CMSSKOrder/EecReview`, {
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        body: qs.stringify({ orderNumber: record['OrderNumber'] })
      }).then(({ json }) => {
        setLoading(false);
        if (json.Code == 1)
          refresh();
        else {
          notify(json.Message);
        }
      })
    }
  }

  return <>
    <Box mr={1}>
      <CButton variant="contained" className={classes.errBtn} onClick={clickHandler}>
        取消审核
      </CButton>
    </Box>
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress />
    </Backdrop>
  </>
}

//SHOW页面
export const SKOrderShow = (props) => {

  const ShowActions = (props) => {
    const redirect = useRedirect();
    const { basePath, data } = props;
    if (!data) return null;
    const { OrderStatusReview, OrderStatusFiling } = data;
    return (
      <TopToolbar>
        {(OrderStatusReview === '创建中' || OrderStatusReview === '驳回审核') &&
          <>
            <SubmitReviewBtn record={data} />
            <CloseOrderBtn record={data} />
          </>
        }
        {(OrderStatusReview === '审核中') &&
          <EecReviewBtn record={data} />
        }
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
        {(OrderStatusReview === '创建中' || OrderStatusReview === '驳回审核') &&
          <EditButton basePath={basePath} record={data} />
        }
      </TopToolbar>
    );
  };

  return (
    <Show title={'查看' + useTxtData.page.tableName} {...props} actions={<ShowActions />}>
      <TabbedShowLayout>
        <Tab label="基础信息">
          {/* <TextField source="id" /> */}
          {/* <TextField label="名称" source="Name" /> */}
          {/* <TextField label="排序" source="OrderNum" />  */}
          {/* <TextField label={useTxtData.table.id} source="id" /> */}
          <TextField label={useTxtData.table.OrderNumber} source="OrderNumber" />
          <TextField label={useTxtData.table.OrderStatusReview} source="OrderStatusReview" />
          <TextField label={useTxtData.table.RejectReviewRemark} source="RejectReviewRemark" />
          <TextField label={useTxtData.table.OrderStatusFiling} source="OrderStatusFiling" />
          <TextField label={useTxtData.table.RejectFilingRemark} source="RejectFilingRemark" />

          {/* <TextField label={useTxtData.table.SalesmanId} source="SalesmanId" />
          <TextField label={useTxtData.table.TravelAgency_AdminId} source="TravelAgency_AdminId" />
          <TextField label={useTxtData.table.Meter_AdminId} source="Meter_AdminId" /> */}

          {/* <ReferenceField label="经销商" source="TravelAgency_AdminId" reference="Meter/CMSAdmin_TravelAgency" link={false} sortable={false}>
            <>
              <TextField source="Account" />(<TextField source="NickName" />)
            </>
          </ReferenceField> */}
          <ReferenceField label="业务员" source={'TravelAgencySalesmanId'} reference="TravelAgency/CMSTravelAgencySalesman" link={false}>
            <TextField source="Name" />
          </ReferenceField>

          <TextField label={useTxtData.table.IourneyTitle} source="IourneyTitle" />

          <FunctionField label={useTxtData.table.IourneyStartTime} sortable={true} sortBy='IourneyStartTime' render={record => <>
            <div>{record['IourneyStartTime'].split(' ')[0]}</div>
          </>} />
          <FunctionField label={useTxtData.table.CreateTime} sortable={true} sortBy='CreateTime' render={record => <>
            <div>{record['CreateTime'].split(' ')[0]}</div>
          </>} />
          <TextField label={useTxtData.table.UpdateTime} source="UpdateTime" />

          <TextField label={useTxtData.table.OrderRemark} source="OrderRemark" />
        </Tab>
        <Tab label="游客信息">
          <FunctionField addLabel={false} render={record => <Visitor record={record} />} />
        </Tab>
        <Tab label="订单日志">
          <FunctionField addLabel={false} render={record => <Log record={record} />} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const SKOrderEdit = (props) => {
  const PostEditActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const SKOrderCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单

const DownloadMB = ({ record }) => {

  const clickHandler = () => {
    let url = `${apiUrl}/Home/DownloadMB`;
    window.location.href = url;
  }

  return <>
    <CButton color="primary" component="span" onClick={clickHandler}>
      下载模版
    </CButton>
  </>
}

const ImportData = ({ record }) => {
  const classes = useStyles();
  const form = useForm();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
  }, []);

  const _uploadFile = async (e) => {
    if (e.target.files.length > 0) {
      setLoading(true);
      var result = await uploadFile({ rawFile: e.target.files[0] });
      console.log('url', result);
      e.target.value = "";
      if (result.indexOf('http') === 0) {
        let filePath = result;
        httpClient(`${apiUrl}/TravelAgency/CMSSKOrder/ImportData`, {
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          body: qs.stringify({ excelUrl: filePath })
        }).then(({ json }) => {
          setLoading(false);
          if (json.Code == 1) {
            //赋值
            form.change('Visitors', json.Result)
          }
          else {
            notify(json.Message);
          }
        })
      }
    }
  }
  return (
    <>
      <input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        className={classes.input}
        id="contained-button-file"
        type="file"
        onChange={_uploadFile}
      />
      <label htmlFor="contained-button-file">
        <CButton color="primary" component="span">
          EXCEL导入
        </CButton>
      </label>
      <Backdrop className={classes.backdrop} open={loading}>
        <Box sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center'
        }}>
          <CircularProgress style={{ color: '#FFF' }} />
          <Typography style={{ color: '#FFF', marginTop: 10 }} variant="subtitle1" gutterBottom>
            导入数据中(可能需要较长时间)，请耐心等待
          </Typography>
        </Box>
      </Backdrop>
    </>
  )
}

const TitleTextInput = (props) => {
  const notify = useNotify();
  const [data, setData] = useState(null);
  useEffect(() => {
    httpClient(`${apiUrl}/TravelAgency/CMSSKOrder/GetIourneyList`, {
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      body: qs.stringify({})
    }).then(({ json }) => {
      //console.log(json);
      if (json.Code == 1) {
        setData(json.Result);
      }
      else {
        notify(json.Message);
      }
    });
  }, []);
  if (!data) return null;
  const dData = () => {
    var list = data.map(item => ({ id: `${item.id}`, name: item.Titlte }))
    // console.log(list)
    return list;
  }
  return (
    <AutocompleteInput {...props} label={useTxtData.table.IourneyTitle} source="IourneyTitle" choices={dData()} />
  )
}

const Agreement = () => {
  const [open, setOpen] = useState(false)
  const [agreement, setAgreement] = useState('');
  useEffect(() => {
    httpClient(apiUrl + '/TravelAgency/Base/GetAgreement', {
      method: 'GET',
      // body: JSON.stringify({}),
    }).then(({ headers, json }) => {
      if (json.Code === 1) {
        setAgreement(json.Result);
        setOpen(true);
      }
    });
  }, []);
  const submitClick = () => {
    setOpen(false);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => { }}
      >
        <DialogTitle>系统下单协议</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ width: 500, minHeight: 300 }}>
            <div style={{ fontSize: 18, fontWeight: 700 }} dangerouslySetInnerHTML={{ __html: agreement.replace(/\n/gmi, '<br />') }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CButton onClick={submitClick} color="primary">
            同意协议, 继续使用
          </CButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const MyForm = (props) => {
  const classes = useStyles();
  let { Edit, Create } = props;

  const [certificateType, SetCertificateType] = useState(null);
  useEffect(() => {
    let EnumName = 'api.Models.TypeOfCertificateType'; let OnlyText = true;
    httpClient(`${apiUrl}/Public/GetEnumVals`, {
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      body: qs.stringify({ EnumName, OnlyText })
    }).then(({ json }) => {
      if (json.Code == 1)
        SetCertificateType(json.Result);
    })
  }, []);

  return (
    <>
      {Create && <Agreement />}
      <TabbedForm {...props} >
        <FormTab label="基础信息">
          {/* <TextInput source="Name" /> */}
          {/* <NumberInput source="OrderNum" /> */}
          {/* <TextInput label={useTxtData.table.id} source="id" validate={[required()]} /> */}
          {/* <TextInput label={useTxtData.table.OrderNumber} source="OrderNumber" validate={[required()]} /> */}
          {/* <TextInput label={useTxtData.table.OrderStatusReview} source="OrderStatusReview" validate={[required()]} /> */}
          {/* <TextInput label={useTxtData.table.RejectReviewRemark} source="RejectReviewRemark" validate={[required()]} /> */}
          {/* <TextInput label={useTxtData.table.OrderStatusFiling} source="OrderStatusFiling" validate={[required()]} /> */}
          {/* <TextInput label={useTxtData.table.RejectFilingRemark} source="RejectFilingRemark" validate={[required()]} /> */}
          {/* <TextInput label={useTxtData.table.SalesmanId} source="SalesmanId" validate={[required()]} /> */}
          {/* <ReferenceInput label={useTxtData.table.SalesmanId} source="SalesmanId" reference="TravelAgency/CMSSalesman" validate={[required()]}>
          <SelectInput optionText="Name" />
        </ReferenceInput> */}
          <ReferenceInput label={useTxtData.table.TravelAgencySalesmanId} source="TravelAgencySalesmanId" reference="TravelAgency/CMSTravelAgencySalesman" validate={[required()]}>
            <SelectInput optionText="Name" />
          </ReferenceInput>

          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                <UseFormComponent>{
                  (form) => (
                    <>

                      <ReferenceInput label={'省'} source="ProvinceId" reference="TravelAgency/CMSProvince"
                        perPage={999}
                        // filter={{ "topId": 0 }}
                        validate={[required()]}
                        onChange={() => {
                          form.change('CityId', null);
                        }}
                      >
                        <SelectInput optionText="Name" />
                      </ReferenceInput>
                      <div style={{ width: 10, display: 'inline-block' }}></div>
                      <ReferenceInput label={'市'} source="CityId" reference="TravelAgency/CMSCity"
                        perPage={999}
                        filter={{ "provinceId": formData.ProvinceId }}
                        validate={[required()]}
                        onChange={() => {
                        }}
                      >
                        <SelectInput optionText="Name" />
                      </ReferenceInput>

                    </>
                  )
                }
                </UseFormComponent>)
            }}
          </FormDataConsumer>

          {/* <TextInput label={useTxtData.table.TravelAgency_AdminId} source="TravelAgency_AdminId" validate={[required()]} /> */}
          {/* <TextInput label={useTxtData.table.Meter_AdminId} source="Meter_AdminId" validate={[required()]} /> */}
          <DateInput label={useTxtData.table.IourneyStartTime} source="IourneyStartTime" validate={[required()]} />

          {Create ?
            <TitleTextInput {...props} label={useTxtData.table.IourneyTitle} fullWidth />
            :
            <TextInput fullWidth label={useTxtData.table.IourneyTitle} source="IourneyTitle" validate={[required()]} />
          }

          {/* <TextInput label={useTxtData.table.CreateTime} source="CreateTime" validate={[required()]} /> */}
          {/* <TextInput label={useTxtData.table.UpdateTime} source="UpdateTime" validate={[required()]} /> */}
          <TextInput fullWidth label={useTxtData.table.OrderRemark} source="OrderRemark" validate={[]} />
        </FormTab>
        <FormTab label="游客信息">
          <Box>
            <DownloadMB />
            <ImportData />
          </Box>
          <ArrayInput source="Visitors" label=''>
            <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
              <FormDataConsumer>
                {({ getSource, scopedFormData, formData }) => (
                  <Box style={{ width: '100%' }}>
                    <Box display="flex" alignItems='center'>
                      <Box mr={2}>
                        <TextInput source={getSource("Name")} label="姓名" validate={[required()]} />
                      </Box>
                      <Box mr={2}>
                        <SelectInput source={getSource("Sex")} label={'性别'} choices={[
                          { id: 1, name: '男' },
                          { id: 2, name: '女' }
                        ]} validate={[required()]} initialValue={1} />
                      </Box>
                      <Box mr={2}>
                        {certificateType &&
                          <SelectByEnum source={getSource("TypeOfCertificate")} label={'证件类型'} EnumName='api.Models.TypeOfCertificateType' validate={[required()]} OnlyText={true} initialValue={'身份证'} data={certificateType} />
                        }
                      </Box>
                      <Box mr={2}>
                        <TextInput source={getSource("CertificateNumber")} label="证件号" validate={[required(), regex(/^\s*(\d{18}|\d{17}X|\d{17}x)\s*$/, '证件格式不正确')]} />
                      </Box>
                      <Box mr={2}>
                        <TextInput source={getSource("Mobile")} label="手机" validate={[]} />
                      </Box>
                      <Box mr={2}>
                        <TextInput style={{ flex: 1 }} source={getSource("TravelAgency_Remark")} label="备注" validate={[]} />
                      </Box>
                    </Box>
                  </Box>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </>
  );
}

// const FormView1 = ({ getSource }) => (
//   <Box style={{ width: '100%' }}>
//     <Box display="flex" alignItems='center'>
//       <Box mr={2}>
//         <TextInput source={getSource("Name")} label="姓名" validate={[required()]} />
//       </Box>
//       <Box mr={2}>
//         <SelectInput source={getSource("Sex")} label={'性别'} choices={[
//           { id: 1, name: '男' },
//           { id: 2, name: '女' }
//         ]} validate={[required()]} initialValue={1} />
//       </Box>
//       <Box mr={2}>
//         {certificateType &&
//           <SelectByEnum source={getSource("TypeOfCertificate")} label={'证件类型'} EnumName='api.Models.TypeOfCertificateType' validate={[required()]} OnlyText={true} initialValue={'身份证'} data={certificateType} />
//         }
//       </Box>
//       <Box mr={2}>
//         <TextInput source={getSource("CertificateNumber")} label="证件号" validate={[required(), regex(/^\s*(\d{18}|\d{17}X|\d{17}x)\s*$/, '证件格式不正确')]} />
//       </Box>
//       <Box mr={2}>
//         <TextInput source={getSource("Mobile")} label="手机" validate={[]} />
//       </Box>
//       <Box mr={2}>
//         <TextInput style={{ flex: 1 }} source={getSource("TravelAgency_Remark")} label="备注" validate={[]} />
//       </Box>
//     </Box>
//   </Box>
// )

const UseFormComponent = ({ children }) => {
  //两个ReferenceInput联动的问题，默认次级选中的值不会被清空
  //https://github.com/marmelab/react-admin/issues/2431 官方的问题
  //https://github.com/marmelab/react-admin/issues/3676#issuecomment-595240330 解决问题的方法
  const form = useForm();
  if (typeof children === 'function')
    return children && children(form);
};