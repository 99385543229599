import React, { useEffect, useState } from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { TextField, Button } from '@material-ui/core';

import { apiUrl } from '../global';
import { useNotify, useRefresh, useRedirect } from 'react-admin';
import { httpClient } from '../dataProvider';
import qs from 'qs';

const Agreement = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const [agreement, setAgreement] = useState('');

    useEffect(() => {
        httpClient(apiUrl + '/Meter/Base/GetAgreement', {
            method: 'GET',
            // body: JSON.stringify({}),
        }).then(({ headers, json }) => {
            if (json.Code === 1) {
                setAgreement(json.Result);
            }
        });
    }, []);

    const _setAgreement = (e) => {
        setAgreement(e.target.value);
    }

    const formSmt = () => {
        httpClient(apiUrl + '/Meter/Base/SetAgreement', {
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            body: qs.stringify({ agreement }),
        }).then(({ json }) => {
            if (json.Code === 1) {
                alert("保存成功");
            }
            else {
                alert(json.Message);
            }
        });
    }

    return (
        <Card>
            <Title title="设定经销商下单协议" />
            <CardContent>
                <form autoComplete="off">
                    <div style={{ display: 'flex', flexDirection: 'column', width: 800, paddingLeft: 25, justifyContent: 'space-between' }}>
                        <TextField multiline style={{ width: '100%' }} required label="下单协议" type="text" value={agreement} onChange={_setAgreement} variant="outlined" rows={10} />
                    </div>
                    <div style={{ paddingLeft: 25, paddingTop: 25 }}>
                        <Button variant="contained" color="primary" onClick={formSmt}>保存</Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    );
};

export default Agreement;