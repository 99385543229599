import * as React from 'react';
import { Fragment } from 'react';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';
import {
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
  CreateButton
} from 'react-admin';
import { UpLoadFile } from './custom/UpLoadFile';
import { ArrowBack } from '@material-ui/icons';
//import ResetOrderNum from './ShoppingStore_ResetOrderNum';

{/* 
  import { ShoppingStoreList, ShoppingStoreCreate, ShoppingStoreEdit } from './components/ShoppingStore';

  <Resource name="CMSShoppingStore" list={ShoppingStoreList} />
  <Resource name="CMSShoppingStore" list={ListGuesser} create={EditGuesser} edit={EditGuesser} show={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
});

const useTxtData = {
  page: {
    tableName: 'ShoppingStore',
  },
  table: {
    id: 'id',
    StoreName: '店铺名称',
    ContactName: '联系人',
    ContactMobile: '联系人手机',
    ContactEmail: '联系人邮箱',
    OrderNum: '排序',
    Remark: '备注',
    CreateTime: '创建时间',
  }
};

//分页列表页面
export const ShoppingStoreList = (props) => {
  const { basePath } = props;
  const redirect = useRedirect();

  //筛选器模块
  const Filters = (props) => {
    return (
      <Filter {...props}>
        <TextInput label="ID" source="id" alwaysOn resettable />
        {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
      </Filter>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" />
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton {...props} /><br />
        <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" />
      </div>
    );
  }
  //表格右上角功能按钮
  const ListActions = ({ basePath }) => {
    return (
      <TopToolbar>
        <CreateButton basePath={basePath} />
      </TopToolbar>
    );
  }
  //返回到列表
  const handleClose = () => {
    redirect(basePath);
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'DESC' }} filters={<Filters />} bulkActionButtons={<AssetBulkActionButtons />} actions={<ListActions />} empty={false}>
        <Datagrid>
          {/* <TextField source="id" /> */}
          {/* <TextField label="名称" source="Name" /> */}
          {/* <TextField label="排序" source="OrderNum" />  */}
          <TextField label={useTxtData.table.id} source="id" />
          <TextField label={useTxtData.table.StoreName} source="StoreName" />
          <TextField label={useTxtData.table.ContactName} source="ContactName" />
          <TextField label={useTxtData.table.ContactMobile} source="ContactMobile" />
          <TextField label={useTxtData.table.ContactEmail} source="ContactEmail" />
          <TextField label={useTxtData.table.OrderNum} source="OrderNum" />
          <TextField label={useTxtData.table.Remark} source="Remark" />
          <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
          <RowAction />
        </Datagrid>
      </List>
      <Route path={`${basePath}/create`} >
        {({ match }) => (
          <Drawer
            open={!!match}
            anchor="right"
            onClose={handleClose}
          >
            <ShoppingStoreCreate {...props}
              onCancel={handleClose}
            />
          </Drawer>
        )}
      </Route>
      <Route path={`${basePath}/:id`}>
        {({ match }) => {
          const isMatch =
            match &&
            match.params &&
            match.params.id !== 'create';
          return (<Drawer
            open={isMatch}
            anchor="right"
            onClose={handleClose}
          >
            {isMatch ? (
              <ShoppingStoreEdit {...props}
                id={isMatch ? match.params.id : null}
                onCancel={handleClose}
              />
            ) : null}
          </Drawer>)
        }}
      </Route>
    </React.Fragment>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const ShoppingStoreEdit = (props) => {
  const PostEditActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const ShoppingStoreCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <TextInput label={useTxtData.table.id} source="id" validate={[required()]} /> */}
      <TextInput label={useTxtData.table.StoreName} source="StoreName" validate={[required()]} />
      <TextInput label={useTxtData.table.ContactName} source="ContactName" validate={[required()]} />
      <TextInput label={useTxtData.table.ContactMobile} source="ContactMobile" validate={[required()]} />
      <TextInput label={useTxtData.table.ContactEmail} source="ContactEmail" validate={[required()]} />
      <NumberInput label={useTxtData.table.OrderNum} source="OrderNum" validate={[]} defaultValue={0} />
      <TextInput label={useTxtData.table.Remark} source="Remark" validate={[]} />
      {/* <TextInput label={useTxtData.table.CreateTime} source="CreateTime" validate={[required()]} /> */}
    </SimpleForm>
  );
}