import * as React from 'react';
import { useState, useEffect } from 'react';
import { Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';
import { Route } from 'react-router-dom';
import qs from 'qs';
import { apiUrl } from './global';
import { httpClient } from './dataProvider';
//import jsonServerProvider from 'ra-data-json-server';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import chinese from 'ra-language-chinese';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import MyLayout from './customLayout/layout';
import LoginPage from './loginPage';
import Dashboard from './dashboardPage';

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ChangePwd from './customLayout/ChangePwd';
import Agreement from './customLayout/Agreement';

import { AdminList } from './components/Admin';
import { AdminList as AdminList_Meter } from './components/Admin_Meter';
import { AdminList as AdminList_TravelAgency, AdminCreate as AdminCreate_TravelAgency, AdminEdit as AdminEdit_TravelAgency } from './components/Admin_TravelAgency';
import { SalesmanList, SalesmanShow } from './components/Salesman';
import { SKOrderList, SKOrderCreate, SKOrderEdit, SKOrderShow } from './components/SKOrder';
import { ShoppingStoreList, ShoppingStoreCreate, ShoppingStoreEdit } from './components/ShoppingStore';
import { SKVipVisitorList } from './components/SKVipVisitor';
import { LecturerList, LecturerCreate, LecturerEdit, LecturerShow } from './components/Lecturer';
import { ProvinceList, ProvinceCreate, ProvinceEdit, ProvinceShow } from './components/Province';
import { CityList, CityCreate, CityEdit, CityShow } from './components/City';

import { AdminList as AdminList_TravelAgency2, AdminCreate as AdminCreate_TravelAgency2, AdminEdit as AdminEdit_TravelAgency2 } from './components/Meter/Admin_TravelAgency';
import { IourneyList, IourneyCreate, IourneyEdit, IourneyShow } from './components/Meter/Iourney';
import { SKOrderList as SKOrderList_Meter, SKOrderEdit as SKOrderEdit_Meter, SKOrderShow as SKOrderShow_Meter } from './components/Meter/Meter_SKOrder';

import { SKOrderList as SKOrderList_TravelAgency, SKOrderEdit as SKOrderEdit_TravelAgency, SKOrderShow as SKOrderShow_TravelAgency, SKOrderCreate as SKOrderCreate_TravelAgency } from './components/TravelAgency/TravelAgency_SKOrder';
import { TravelAgencySalesmanList, TravelAgencySalesmanCreate, TravelAgencySalesmanEdit } from './components/TravelAgency/TravelAgencySalesman';


import StoreIcon from '@material-ui/icons/Store';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ReorderIcon from '@material-ui/icons/Reorder';

//补充缺失的翻译内容 一下是增加一个confirm的中文翻译
chinese.ra.action = { ...chinese.ra.action, confirm: '确认' };
//设置语言为 中文
const i18nProvider = polyglotI18nProvider(() => chinese, 'zh');

const customRoutes = [
  <Route exact path="/ChangePwd" component={ChangePwd} />,
  <Route exact path="/Agreement" component={Agreement} />,
];

const App = () => {

  return (
    <Admin
      title="后台管理"
      layout={MyLayout}
      customRoutes={customRoutes}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={LoginPage}
      dashboard={Dashboard}
    >
      {permissions => {
        //console.log('permissions', permissions)
        if (permissions === 'Admin')
          return [
            <Resource name="Admin/CMSTravelAgencySalesman" />,
            <Resource options={{ group: '成员', label: '管理员' }} name="Admin/CMSAdmin" list={AdminList} icon={<SupervisorAccountIcon />} />,
            <Resource options={{ group: '成员', label: '计调员' }} name="Admin/CMSAdmin_Meter" list={AdminList_Meter} icon={<SupervisorAccountIcon />} />,
            <Resource options={{ group: '成员', label: '经销商' }} name="Admin/CMSAdmin_TravelAgency" list={AdminList_TravelAgency} create={AdminCreate_TravelAgency} edit={AdminEdit_TravelAgency} icon={<SupervisorAccountIcon />} />,
            <Resource options={{ group: '成员', label: '业务员' }} name="Admin/CMSSalesman" list={SalesmanList} show={SalesmanShow} icon={<SupervisorAccountIcon />} />,
            <Resource options={{ group: '成员', label: '讲师' }} name="Admin/CMSLecturer" list={LecturerList} create={LecturerCreate} edit={LecturerEdit} icon={<SupervisorAccountIcon />} />,

            <Resource options={{ group: '区域', label: '省' }} name="Admin/CMSProvince" list={ProvinceList} create={ProvinceCreate} edit={ProvinceEdit} />,
            <Resource options={{ group: '区域', label: '市' }} name="Admin/CMSCity" list={CityList} create={CityCreate} edit={CityEdit} />,

            <Resource options={{ group: '店铺', label: '列表' }} name="Admin/CMSShoppingStore" list={ShoppingStoreList} icon={<StoreIcon />} />,
            <Resource options={{ group: '业务', label: '订单' }} name="Admin/CMSSKOrder" list={SKOrderList} edit={SKOrderEdit} show={SKOrderShow} icon={<AssignmentIcon />} />,
            <Resource options={{ group: '业务', label: 'VIP' }} name="Admin/CMSSKVipVisitor" list={SKVipVisitorList} icon={<AssignmentIndIcon />} />,
            <Resource options={{ group: '业务', label: '回收站' }} name="Admin/CMSSKOrderRecycle" list={SKOrderList} show={SKOrderShow} icon={<DeleteForeverIcon />} />,
          ]
        if (permissions === 'Meter')
          return [
            <Resource name="Meter/CMSSalesman" />,
            <Resource name="Meter/CMSTravelAgencySalesman" />,
            <Resource name="Meter/CMSLecturer" />,
            <Resource name="Meter/CMSProvince" />,
            <Resource name="Meter/CMSCity" />,
            <Resource options={{ group: '经销商', label: '列表' }} name="Meter/CMSAdmin_TravelAgency" list={AdminList_TravelAgency2} create={AdminCreate_TravelAgency2} edit={AdminEdit_TravelAgency2} icon={<SupervisorAccountIcon />} />,
            <Resource options={{ group: '行程', label: '列表' }} name="Meter/CMSIourney" list={IourneyList} create={IourneyCreate} edit={IourneyEdit} show={IourneyShow} icon={<ReorderIcon />} />,
            <Resource options={{ group: '业务', label: `订单` }} name="Meter/CMSSKOrder" list={SKOrderList_Meter} edit={SKOrderEdit_Meter} show={SKOrderShow_Meter} icon={<AssignmentIcon />} />,
            <Resource options={{ group: '业务', label: '回收站' }} name="Meter/CMSSKOrderRecycle" list={SKOrderList_Meter} show={SKOrderShow_Meter} icon={<DeleteForeverIcon />} />,
          ]
        if (permissions === 'TravelAgency')
          return [
            <Resource name="TravelAgency/CMSProvince" />,
            <Resource name="TravelAgency/CMSCity" />,
            <Resource options={{ group: '成员', label: '业务员' }} name="TravelAgency/CMSTravelAgencySalesman" list={TravelAgencySalesmanList} icon={<SupervisorAccountIcon />} />,
            <Resource options={{ group: '业务', label: '订单' }} name="TravelAgency/CMSSKOrder" list={SKOrderList_TravelAgency} edit={SKOrderEdit_TravelAgency} create={SKOrderCreate_TravelAgency} show={SKOrderShow_TravelAgency} icon={<AssignmentIcon />} />,
            <Resource options={{ group: '业务', label: '回收站' }} name="TravelAgency/CMSSKOrderRecycle" list={SKOrderList_TravelAgency} show={SKOrderShow_TravelAgency} icon={<DeleteForeverIcon />} />,
          ]
      }}
    </Admin>
  )
};

export default App;