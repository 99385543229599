import * as React from 'react';
import { forwardRef } from 'react';
import { useState, useEffect } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate, useRedirect, useAuthProvider } from 'react-admin';
import qs from 'qs';
import { apiUrl } from '../global';
import { httpClient } from '../dataProvider';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FontDownloadIcon from '@material-ui/icons/FontDownload';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const ConfigurationMenu = forwardRef((props, ref) => {
    return (
        <MenuItemLink
            ref={ref}
            to="/ChangePwd"
            primaryText={'修改密码'}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const CustomUserMenu = (props) => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
);

const defaultProps = {
    color: 'error',
    children: <MailIcon />,
};

const MyAppBar = (props) => {
    const redirect = useRedirect();
    const authProvider = useAuthProvider();
    const [pendingCount, setPendingCount] = React.useState(0);
    const [permissions, setPermissions] = React.useState('');

    useEffect(() => {
        let interval;
        (async () => {
            let permissions = await authProvider.getPermissions();
            console.log('permissions', permissions);
            setPermissions(permissions);
            if (permissions === 'Meter' || permissions === 'Admin') {
                interval = setInterval(() => {
                    httpClient(`${apiUrl}/${permissions}/CMSSKOrder/GetPendingCount`, {
                        method: 'POST',
                        contentType: 'application/x-www-form-urlencoded',
                        body: qs.stringify({})
                    }).then(({ json }) => {
                        if (json.Code == 1)
                            setPendingCount(json.Result);
                    })
                }, 1000 * 60 * 60);
            }
        })();
        return () => { try { clearInterval(interval); } catch (e) { } }
    }, []);

    const classes = useStyles();
    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />
            {pendingCount > 0 &&
                <IconButton style={{ color: '#FFF' }} onClick={() => redirect(`/${permissions}/CMSSKOrder`)}>
                    <Badge badgeContent={pendingCount} max={999} {...defaultProps} />
                </IconButton>
            }
            {permissions === 'Meter' &&
                <Button
                    // variant="contained"
                    style={{ color: '#FFF' }}
                    startIcon={<FontDownloadIcon />}
                    onClick={() => {
                        redirect(`/Agreement`);
                    }}
                >
                    经销商协议
                </Button>
            }
        </AppBar>
    );
};

export default MyAppBar;