import * as React from 'react';
import { Fragment } from 'react';
import { Route } from 'react-router';
import { Drawer, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { httpClient } from '../dataProvider';
import { apiUrl } from '../global';
import qs from 'qs';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, useNotify, FunctionField } from 'react-admin';
import {
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
  CreateButton,
  ChipField
} from 'react-admin';
import { UpLoadFile, PreviewImage } from './custom/UpLoadFile';
import { ArrowBack } from '@material-ui/icons';
import { SelectByEnum } from './custom/Enum';
//import ResetOrderNum from './Admin_ResetOrderNum';

{/* 
  import { AdminList, AdminCreate, AdminEdit } from './components/Admin';

  <Resource name="CMSAdmin" list={AdminList} />
  <Resource name="CMSAdmin" list={ListGuesser} create={EditGuesser} edit={EditGuesser} show={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
});

const useTxtData = {
  page: {
    tableName: '经销商',
  },
  table: {
    id: 'id',
    TravelAgency_GradeType: '评级',
    SalesmanId: '所属业务员',
    Account: '账号',
    NickName: '昵称',
    Password: '密码',
    Sex: '性别',
    Mobile: '手机',
    Email: '邮箱',
    Avatar: '头像',
    CreateTime: '创建时间',
    UpdateTime: '更新时间',
    Open: '启用',
    Remark: '备注',
  }
};

//分页列表页面
export const AdminList = (props) => {
  const { basePath } = props;
  const redirect = useRedirect();

  //筛选器模块
  const Filters = (props) => {
    return (
      <Filter {...props}>
        {/* <TextInput label="ID" source="id" alwaysOn resettable /> */}
        {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
      </Filter>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        {/* <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton {...props} /><br />
        {/* <DeleteWithConfirmButton {...props} undoable={false} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const classes = useStyles();
  return (
    <React.Fragment>
      <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'ASC' }} filters={<Filters />} filterDefaultValues={{ adminType: 'TravelAgency' }} bulkActionButtons={false} exporter={false}>
        <Datagrid>
          {/* <TextField source="id" /> */}
          {/* <TextField label="名称" source="Name" /> */}
          {/* <TextField label="排序" source="OrderNum" />  */}
          <TextField label={useTxtData.table.id} source="id" />
          <ReferenceField label="所属计调" source="Meter_AdminId" reference="Admin/CMSAdmin_Meter" link
            ={false}>
            <>
              <TextField source="Account" />(<TextField source="NickName" />)
            </>
          </ReferenceField>
          <ReferenceField label={useTxtData.table.SalesmanId} source="SalesmanId" reference="Admin/CMSSalesman" link
            ={false}>
            <>
              <TextField source="Name" />
            </>
          </ReferenceField>

          <PreviewImage label={useTxtData.table.Avatar} source="Avatar" className={classes.imageField} />

          <FunctionField label={useTxtData.table.TravelAgency_GradeType} render={record => {
            var val = record['TravelAgency_GradeType'];
            var color = "";
            switch (val) {
              case '优': color = { backgroundColor: 'green', color: 'white' }; break;
              case '良': color = { backgroundColor: 'yellow' }; break;
              case '差': color = { backgroundColor: 'grep' }; break;
            }
            if (val) return <Chip label={val} style={color} />
          }} />
          {/* <ChipField label={useTxtData.table.TravelAgency_GradeType} source="TravelAgency_GradeType" /> */}

          <TextField label={useTxtData.table.Account} source="Account" />
          <TextField label={useTxtData.table.NickName} source="NickName" />
          <FunctionField label={useTxtData.table.Sex} render={record => <div>{record['Sex'] === 2 ? '女' : '男'}</div>} />
          <TextField label={useTxtData.table.Mobile} source="Mobile" />
          <TextField label={useTxtData.table.Email} source="Email" />
          <BooleanField label={useTxtData.table.Open} source="Open" />
          <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
          <TextField label={useTxtData.table.UpdateTime} source="UpdateTime" />
          <RowAction />
        </Datagrid>
      </List>
    </React.Fragment>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const AdminEdit = (props) => {
  //console.log('props123', props);
  const ReSetpwd = () => {
    var notify = useNotify()
    const repwd = () => {
      if (window.confirm('确定要重置密码？')) {
        httpClient(`${apiUrl}/Admin/CMSAdmin/ResetPwd`, {
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          body: qs.stringify({ adminId: props.id })
        }).then(({ json }) => {
          if (json.Code == 1)
            notify('重置成功，新密码：' + json.Result)
          else
            notify(json.Message)
        })
      }
    }
    return (
      <>
        <Button label="重置密码" onClick={repwd} variant="text"></Button>
        <Button label="重置密码" onClick={repwd} variant="text"></Button>
      </>
    )
  }

  const PostEditActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
        <ReSetpwd />
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const AdminCreate = (props) => {
  //return null;
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <TextInput label={useTxtData.table.id} source="id" validate={[required()]} /> */}

      <ReferenceInput label="所属计调" source="Meter_AdminId" reference="Admin/CMSAdmin_Meter"
        filter={{ adminType: 'Meter' }}>
        <SelectInput optionText={record => `${record['Account']}(${record['NickName']})`} />
      </ReferenceInput>

      <ReferenceInput label={useTxtData.table.SalesmanId} source="SalesmanId" reference="Admin/CMSSalesman">
        <SelectInput optionText={'Name'} />
      </ReferenceInput>

      <UpLoadFile label={useTxtData.table.Avatar} source="Avatar" validate={[]} />

      {Create &&
        <TextInput label={useTxtData.table.Account} source="Account" validate={[required()]} />
      }

      <SelectByEnum label={useTxtData.table.TravelAgency_GradeType} source="TravelAgency_GradeType" EnumName='api.Models.TravelAgency_Grade' OnlyText={true} allowEmpty={true} />

      <TextInput label={useTxtData.table.NickName} source="NickName" validate={[required()]} />
      {Create &&
        <TextInput label={useTxtData.table.Password} source="Password" validate={[required()]} />
      }

      <SelectInput label={useTxtData.table.Sex} source="Sex" choices={[
        { id: 1, name: '男' },
        { id: 2, name: '女' }
      ]} validate={[required()]} />

      <TextInput label={useTxtData.table.Mobile} source="Mobile" validate={[]} />
      <TextInput label={useTxtData.table.Email} source="Email" validate={[]} />
      <BooleanInput label={useTxtData.table.Open} source="Open" defaultValue={true} />
      <TextInput fullWidth label={useTxtData.table.Remark} source="Remark" />
    </SimpleForm>
  );
}